/* ==========================================================================
  main.js
========================================================================== */

/**
 * Init dropdown-menus
 */
var dropdown_menus = document.querySelectorAll(".js-navigation");
for (var i = 0; i < dropdown_menus.length; i++) {
  component_dropdown_menu({
    desktop_min_width: 890,
    menu: dropdown_menus[i],
  });
}

/**
 * Init mobile menu
 */
component_mobile_menu({
  menu: document.querySelector(".js-mobile-menu"),
  site: document.querySelector(".js-page"),
  toggles: document.querySelectorAll(".js-menu-toggle"),
});

/**
 * Init Responsive videos
 */
fitvids();

/**
 * Init polyfill object-fit for lazyloaded
 */
if (typeof objectFitPolyfill === "function") {
  document.addEventListener("lazybeforeunveil", function (e) {
    objectFitPolyfill();
    e.target.addEventListener("load", function () {
      objectFitPolyfill();
    });
  });
}

/**
 * Init lightbox
 */
if (document.querySelectorAll(".lightbox").length) {
  try {
    new Tobii({
      // variables from script localization in register-assets.php
      navLabel: [theme_strings.prev, theme_strings.next],
      closeLabel: theme_strings.close,
      loadingIndicatorLabel: theme_strings.loading,
      captionsSelector: "self",
      captionAttribute: "data-caption",
      zoom: false,
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Init Testimonials slider
 */
if (document.querySelectorAll(".slider-testimonials--active").length) {
  document.addEventListener("DOMContentLoaded", function () {
    new Splide(".slider-testimonials--active", {
      perPage: 1,
      type: "fade",
      pagination: false,
      arrows: true,
    }).mount();
  });
}

/**
 * Annonce slider in single annonce page
 */
if (document.querySelectorAll("#slider-images").length) {
  document.addEventListener("DOMContentLoaded", function () {
    var secondarySlider = new Splide("#slider-thumbs", {
      type: "loop",
      direction: "ttb",
      height: "32rem",
      perPage: 3,
      arrows: false,
      pagination: false,
      gap: 12,
      cover: true,
      isNavigation: true,
      autoplay: true,
      breakpoints: {
        1023: {
          destroy: true,
        },
      },
    }).mount();

    var primarySlider = new Splide("#slider-images", {
      type: "fade",
      height: "32rem",
      perPage: 1,
      arrows: false,
      pagination: false,
      cover: true,
      breakpoints: {
        1023: {
          height: 0,
          pagination: true,
        },
      },
    }); // do not call mount() here.

    primarySlider.sync(secondarySlider).mount();
  });
}

/**
 * Annonce slider in single realisation page
 */
if (document.querySelectorAll("#slider-modele").length) {
  document.addEventListener("DOMContentLoaded", function () {
    var modeleSlider = new Splide("#slider-modele", {
      type: "loop",
      height: "40rem",
      perPage: 1,
      arrows: true,
      pagination: true,
      autoplay: true,
      breakpoints: {
        1023: {
          height: 0,
        },
      },
    }).mount();
  });
}

/**
 * FAQ accordion with Houdinijs plugin
 */
if (document.querySelectorAll(".faq-container").length) {
  var accordion = new Houdini("[data-houdini-group]", {
    isAccordion: true,
    collapseOthers: true,
    icon: false,
  });

  if (window.location.hash) {
    var id = location.hash.substr(1);
    var tab = document.getElementById(id);

    if (typeof tab !== "undefined" && tab !== null) {
      var hash = location.hash;
      accordion.toggle(hash);
      history.replaceState(null, null, " ");
    }
  }
}
